import React, { useMemo } from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { GET_PREOFFERS_REQUESTS_ORDERING } from '@fingo/lib/graphql/customers';
import Typography from '@mui/material/Typography';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import riskEvaluationsColumns from '../../constants/risk-evaluations-columns';
import RiskEvaluationOrderingColumnsByCountry from '../../constants/risk-evaluation-view-colums';
import OrderingSummary from './OrderingSummary';

const RiskEvaluationViewOrdering = () => {
  const country = useGetCountryFromUrl();
  const includingColumns = useMemo(() => {
    if (country?.name) {
      return RiskEvaluationOrderingColumnsByCountry[country.name];
    }
    return [];
  }, [country]);

  return (
    <>
      <FingoMainView
        id="risk-evaluations"
        query={GET_PREOFFERS_REQUESTS_ORDERING}
        queryCustomVariables={{
          inOrderingEvaluation: true,
          countryId: country?.id,
        }}
        slots={{
          header: PaperHeader,
          table: FingoTable,
        }}
        slotProps={{
          header: {
            viewTitle: 'Solicitudes en Evaluación',
            finder: {
              searchPlaceHolder: 'Buscar empresa',
            },
          },
          table: {
            includeHeaders: includingColumns,
            columns: useMasterEntityPreColumns(
              riskEvaluationsColumns('orderingInEvaluation'),
            ),
            checkboxSelection: false,
            isRowSelectable: () => true,
            collapsible: true,
            isRowCollapsible: () => true,
            collapseComponent: ({ row }) => (
              <OrderingSummary id={row?.id} rut={row?.rut} name={row?.name} />
            ),
            disableSelectionOnClick: true,
            noRowsMessage: () => <Typography>No hay evaluaciones pendientes</Typography>,
            rowsPerPageOptions: [10, 15],
          },
        }}
      />
    </>
  );
};

export default RiskEvaluationViewOrdering;
