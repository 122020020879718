import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import useOrderingLucilaPreColumns from '@fingo/lib/constants/ordering-lucila-pre-columns';
import { GET_COMPANY_ORDERING_EVALUATIONS } from '@fingo/lib/graphql/customers';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EvaluationActions from './EvaluationActions';
import riskEvaluationsDocumentColumns from '../../constants/risk-evaluation-document-columns';

const OrderingSummary = ({ id, rut, name }) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const includingColumns = [
    'orderNumber',
    'purchaser_Name',
    'orderingsimulation_InvoiceIssuedDate',
    'orderingsimulation_InvoiceDateToPay',
    'totalAmount',
    'riskVariables',
    'riskPredictionResults',
    'riskResults',
    'reExecute',
    'notes',
    'evaluations',
  ];
  return (
    <Stack sx={{ height: 410 }}>
      <FingoMainView
        id="ordering-evaluation-purchaseOrders"
        query={GET_COMPANY_ORDERING_EVALUATIONS}
        queryCustomVariables={{
          companyId: id,
          inEvaluation: true,
        }}
        slots={{
          table: FingoDataGrid,
          actions: EvaluationActions,
        }}
        slotProps={{
          header: { viewTitle: 'Evaluaciones' },
          table: {
            checkboxSelection: true,
            hideFooter: true,
            columns: useOrderingLucilaPreColumns(
              riskEvaluationsDocumentColumns('purchaseorder'),
            ),
            isRowSelectable: () => true,
            initialOrderBy: 'orderNumber',
            noRowsMessage: () => <Typography>Sin evaluaciones</Typography>,
            onSelectionModelChange: setSelectedDocuments,
            includeHeaders: includingColumns,
            mobileHeaders: [
              'orderNumber',
            ],
            rowsPerPageOptions: [100, 150, 200],
          },
          actions: {
            filter: 'purchaseOrder',
            companyId: id,
            companyRut: rut,
            companyName: name,
            selectedDocuments,
            setSelectedDocuments,
            documentType: 'purchase_order',
          },
        }}
      />
    </Stack>
  );
};
OrderingSummary.propTypes = {
  name: PropTypes.string.isRequired,
  rut: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default OrderingSummary;
